import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Map = () => {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/points`);
        setPoints(response.data);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };
    fetchPoints();
  }, []);

  useEffect(() => {
    const initMap = () => {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 49.018660471113876, lng: 12.096044219770803 },
        zoom: 15,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }
        ],
        mapTypeControl: false,
        streetViewControl: false
      });

      let currentInfoWindow = null;

      if (Array.isArray(points)) {
        points.forEach(point => {
          const marker = new window.google.maps.Marker({
            position: { lat: point.latitude, lng: point.longitude },
            map,
            title: point.name,
            icon: {
              url: './icons/' + point.icon + '.png',
              scaledSize: new window.google.maps.Size(50, 50),
              labelOrigin: new window.google.maps.Point(25, 60),
            },
            label: {
              text: point.name,
              color: '#8B4513',
              fontSize: '12px',
              fontWeight: 'bold',
            },
            animation: window.google.maps.Animation.DROP,
          });

          const infoWindow = new window.google.maps.InfoWindow({
            content: `<div style="font-size: 12px; color: black;"><strong>${point.name}</strong><br>${point.description}</div>`,
          });

          marker.addListener('click', () => {
            if (currentInfoWindow) {
              currentInfoWindow.close();
            }
            infoWindow.open(map, marker);
            currentInfoWindow = infoWindow;
          });
        });
      } else {
        console.error('Points is not an array:', points);
      }
    };

    const loadGoogleMapsScript = () => {
      const existingScript = document.querySelector(`script[src*="maps.googleapis.com"]`);
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_API_KEY}&callback=initMap&libraries=&v=weekly&loading=async`;
        script.async = true;
        script.defer = true;
        window.initMap = initMap; // Assign the function to window before appending the script
        document.head.appendChild(script);
      } else {
        window.initMap = initMap; // Reassign the function in case the script already exists
        initMap();
      }
    };

    loadGoogleMapsScript();

    // Clean up script if the component unmounts
    return () => {
      const script = document.querySelector(`script[src*="maps.googleapis.com"]`);
      if (script) {
        document.head.removeChild(script);
      }
      delete window.initMap;
    };
  }, [points]);

  return <div id="map" style={{ width: '90%' }}></div>;
};

export default Map;